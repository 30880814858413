import { ToastController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PrivilegesEnum } from './dto';

export abstract class  BaseComponent {
    protected $initialized = new BehaviorSubject(false);
    protected Debug:boolean = true;
    protected abstract TRANSLATE_KEY: string;
    protected static isLoadingDialogShow: boolean = false;
    public get IsLoading():boolean {
        return BaseComponent.isLoadingDialogShow;
    }
    private static loadingDialog: any = null;
    constructor(
        protected toastController: ToastController,
        protected loadingController: LoadingController,
        protected translateService: TranslateService
    ) {

    }

    public get ThemePath():string {
        return `assets/${environment.theme}`;
    }

    public getThemeAsset(assetsPath:string):string{
        return `${this.ThemePath}${assetsPath}`;
    }

    public IsRootTheme():boolean{
        return environment.theme == "dmonitor";
    }

    protected log(msg:string):void{
        if(this.Debug == false){
            return;
        }
        console.log(`${this.TRANSLATE_KEY} - ${msg}`);
    }

    protected IsEditRole(actualPrivilege:string,isSuperadmin:boolean):boolean{
        
        if(isSuperadmin){
          console.log("Actual privilege: Superadmin");
          return true;
        }
        console.log(`Actual privilege: ${actualPrivilege}`);
        return Number(actualPrivilege) == PrivilegesEnum.EDIT ? true : false;
      }

    protected translate(title: string): string {
        return this.translateInstant(`${this.TRANSLATE_KEY}.${title}`);
    }

    protected translateInstant(title: string): string {
        const t = this.translateService.instant(title);
        if(t == title){
            this.log(`TRANSLATE MISSING: ${title}`);
        }
        return t;
    }

    protected async presentLoading(msg = null) {
        this.log('try present loading');
        if (BaseComponent.isLoadingDialogShow) {
            return;
        }
        BaseComponent.isLoadingDialogShow = true;
        if(msg == null){
            msg = await this.translateInstant('dialog_loading_msg');
        }
        
        if(msg == 'dialog_loading_msg'){
            msg = '';
        }
        if (BaseComponent.loadingDialog == null) {
            BaseComponent.loadingDialog = await this.loadingController.create({
                message: msg,
            });

        }
        this.log('present loading');
        await BaseComponent.loadingDialog.present();
        this.log(BaseComponent.loadingDialog);
        const { role, data } = await BaseComponent.loadingDialog.onDidDismiss();
    }

    protected async presentLoadingWith(timeout:number = 0,callback : () => void = null){
        this.presentLoading();
        setTimeout(callback,timeout);
    }

    protected async dismissLoading(timeOut = 0) {
        setTimeout(() => {
            this.log('try dismiss loading');
            if (BaseComponent.isLoadingDialogShow == false) {
                return;
            }
            BaseComponent.isLoadingDialogShow = false;
            if (BaseComponent.loadingDialog == null) {
                return;
            }
            this.log('dismiss loading ');
            BaseComponent.loadingDialog.dismiss();
            //BaseComponent.loadingDialog = null;
        },timeOut);

    }

    protected async showSuccessToast(msg: string) {
        this.showToast(msg, "success");
    }

    protected async showFailedToast(msg: string) {
        this.showToast(msg, "danger");
    }

    protected async showErrorToast(msg: string, err: any = null) {
        if (err == null) {
            this.showToast(msg, "danger");
            return;
        }
        if (err.error != null && err.error.result != null) {
            this.showErrorToast(msg + " - " + err.error.result);
        } else {
            this.showErrorToast(msg + " - " + err.message);
        }

    }

    private async showToast(msg: string, color: string, position: 'top' | 'bottom' | 'middle' = 'bottom', duration: number = 3000) {
        const toast = await this.toastController.create({
            message: msg,
            position: position,
            duration: duration,
            color: color
        });
        toast.present();

    }
}
