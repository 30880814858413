import { Component, OnInit } from '@angular/core';
import { HomeService, UserService } from '../services';
import { UserData } from '../shared/dto/userdata.dto';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent, UrlHelper } from '../shared';
import { ToastController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.page.pug',
  styleUrls: ['./user.page.sass'],
})
export class UserPage extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = '';

  constructor(
    toastController:ToastController,
    loadingController:LoadingController,
    translateService:TranslateService,
    private homeService: HomeService, 
    private route: ActivatedRoute) { 
      super(toastController,loadingController,translateService);
    }
  private backLink:string;
  
  private userId:string;
  private isList: boolean;
  ngOnInit() {
    this.backLink = "/"+this.homeService.getHome();
    this.userId = this.route.snapshot.paramMap.get("userId");
    if(this.userId == null || this.userId == ""){
      this.isList = true;
    }else{
      this.backLink =`/${UrlHelper.URL_USER}`;
      this.isList = false;
    }
    this.presentLoading();
  }

  
}
