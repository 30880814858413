import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable} from 'rxjs';
import { EquipmentService } from './equipment.service';
import { filter } from 'rxjs/operators';
import { Site } from '@dmonitor/shared/dto';
import { DefaultResponse } from '@dmonitor/shared/default-response';
import { UserService } from './user.service';
import { SelectableServiceBase } from './selectable.service.base';
import { CompanyService } from './company.service';
import { SitePrivilege } from '@dmonitor/shared/dto/siteprivilege.dto';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SiteService extends SelectableServiceBase<string>{
  private needsToReInitPageSubject = new BehaviorSubject(false);
  public sites : Array<Site> = [];
  public ErrorSubject = new BehaviorSubject<HttpErrorResponse>(null);
  constructor(
    protected http: HttpClient, 
    protected cookiService: CookieService,
    private equipmentService: EquipmentService, 
    private companyService: CompanyService,
    private userService: UserService) {
      super();
     }

  get NeedsToReInitPage(): BehaviorSubject<boolean>{
    return this.needsToReInitPageSubject;
  }

  public getSites(companyId:number, countyId:number){
    return this.http.get<any>(`${environment.apiUrl}/site?cid=${companyId}&countyid=${countyId}&apikey=${environment.apiKey}`,this.getHttpOptions());
  }

  public getAllSitesForCompanies(companyIds:Array<number>, adData:Array<number> = []){
    return this.http.get<any>(`${environment.apiUrl}/site?cid=${JSON.stringify(companyIds)}&ad=${JSON.stringify(adData)}&apikey=${environment.apiKey}`,this.getHttpOptions());
  }

  public Initialize(){
    
    const companyIds:Array<number> = this.companyService.getCompanyIds();
    /*
    this.getAllSitesForCompanies(companyIds, []).subscribe(
      (res: DefaultResponse) => {
        let sites = res.result;
        let filteredSites = [];
        sites.forEach((s: Site) => {
          this.userService.getSitePrivilege().forEach(
            (sp: SitePrivilege) => {
              if(s.id == sp.site_id.toString() && sp.privilege_id.toString() != "0"){
                filteredSites.push(s);
                return;
              }
          });
        });
        this.sites = filteredSites;
        if(this.equipmentService.Inited().value == false){
          this.equipmentService.Initialize(filteredSites);
        }
        this.ErrorSubject.next(null);
        this.Inited().next(true);
      },
      (err) => {
        console.log(err)
        this.ErrorSubject.next(err);
      }
    );
    */
    
  }

  public add(companyId:string, countyId:string, siteName:string){
    
    const postData = JSON.stringify({
      name: siteName,
      company_id: companyId,
      county_id: countyId
    });
    return this.http.post<object>(`${environment.apiUrl}/site/add?apikey=${environment.apiKey}`, postData, this.getHttpOptions());
  }

  public edit(siteId:string,siteName:string){
    
    const postData = JSON.stringify({
      id: siteId,
      name: siteName
    });
    return this.http.post<object>(`${environment.apiUrl}/site/edit?apikey=${environment.apiKey}`, postData, this.getHttpOptions());
  }

  public delete(siteId:string){
    const postData = JSON.stringify({
      id: siteId,
    });
    return this.http.post<object>(`${environment.apiUrl}/site/delete?apikey=${environment.apiKey}`, postData, this.getHttpOptions());

  }

  public getSelectedSite():Site{
    let c: Site[];
    const selectedSiteId = this.GetSelected();
    c = this.sites.filter((site)=>{
      return site.id == selectedSiteId;
    })
    return c[0];    
  }


  public getSiteById(siteId:string):Site{
    let c: Site[];
    const selectedSiteId = this.GetSelected();
    c = this.sites.filter((site)=>{
      return site.id == siteId;
    })
    return c[0];    
  }

  public setModified(site:Site){
    this.sites.forEach((s:Site) => {
      if(s.id == site.id){
        s.name = site.name;
      }
    });
  }

  public setNew(site:Site){
    this.sites.push(site);
  }
  

  public getSiteBySelectedCounty(countyId){
    let filtered = [];
    this.sites.forEach((site) => {
      if(site.county_id == countyId){
        filtered.push(site);
      }
    });
    return filtered;
  }

  public setNeedsToReInitPage(reinit : boolean){
    this.needsToReInitPageSubject.next(reinit);
  }

}
