import { Component, OnInit } from '@angular/core';
import { HomeService } from '@dmonitor/services/home.service';
import { BaseComponent } from '@dmonitor/shared/base.component';
import { LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.page.pug',
  styleUrls: ['./about.page.sass'],
})
export class AboutPage extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string;
  private backLink:string;
  constructor(
    translateService: TranslateService,
    toastController: ToastController,
    loadingController: LoadingController,
    private homeService: HomeService
  ) { 
    super(toastController,loadingController,translateService);
  }

  public ngOnInit() {
    this.backLink = "/"+this.homeService.getHome();

  }

}
