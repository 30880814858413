import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { CountyService } from 'src/app/services/county.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { County } from '@dmonitor/shared/dto';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@dmonitor/services/index';
import { BaseComponent } from '@dmonitor/shared/index';

@Component({
  selector: 'app-county-dialog',
  templateUrl: './county-dialog.component.pug',
  styleUrls: ['./county-dialog.component.sass'],
})
export class CountyDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.county_dialog';
  @Input() isNew: boolean = false;
  private county: County;
  public title = '';
  public validation_messages = [
    { type: 'required', message: 'Terület név kötelező' },
    { type: 'minlength', message: 'Terület név hossza minimum 3 karakter hosszú.' },
  ];

  public name = new FormControl('',
    Validators.compose([
      Validators.required,
      Validators.minLength(3)
    ])
  );

  public form = new FormGroup({
    countyname: this.name,
  });

  constructor(
    public modalController: ModalController,
    translateService: TranslateService,
    loadingController: LoadingController,
    private dataService: DataService,
    toastController: ToastController
  ) {
    super(toastController, loadingController, translateService);
  }

  
  public ngOnInit() {
    this.validation_messages = [
      { type: 'required', message: this.translate('msg_error_required') },
      { type: 'minlength', message: this.translate('msg_error_min_character') },
    ];
    this.dismissLoading(1000);

    if (this.isNew) {
      this.title = this.translate('title_add');
      return;
    }
    this.title = this.translate('title_modify');
    this.county = this.dataService.GetCounty(this.dataService.CountyService.GetSelected());
    this.name.setValue(this.county.name);
  }

  public dismiss(success: Boolean) {
    this.modalController.dismiss(success);
  }

  public onClickSubmit(data) {
    this.presentLoadingWith(1000, () => {
      if (this.isNew) {
        this.addCounty();
      } else {
        this.editCounty();
      }
    });
  }

  private addCounty(): void {
    this.dataService.CountyService.add(this.name.value).subscribe(
      (res: any) => {
        this.dismissLoading();
        if (res.result.success == true) {     
          this.dataService.ReloadUserRights();     
          this.showSuccessToast(this.translate('msg_success_modify'));
          this.dismiss(true);
        } else {
          this.showFailedToast(this.translate('msg_save_error'));
        }
      },
      (error: any) => {
        this.dismissLoading();
        this.showErrorToast(this.translate('msg_save_error_modify'),error);
      }
    );
  }


  private editCounty(): void {
    this.dataService.CountyService.edit(this.county.id, this.name.value).subscribe(
      (res: any) => {
        if (res.result.success == true) {
          
          this.showSuccessToast(this.translate('msg_success'));

          this.dismiss(true);
        } else {
          this.showFailedToast(this.translate('msg_save_error_modify'));
          this.dismiss(true);
        }
      },
      (error: any) => {
        this.dismissLoading();
        this.showErrorToast(this.translate('msg_save_error_modify'),error);
      }
    );
  }


}
