import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SensorErrorModule } from '@dmonitor/sensors/sensorerror/sensorerror.module';
import { SensorLoadingModule } from '@dmonitor/sensors/sensorloading/sensorloading.module';
import { ParametersComponent } from '../statusindicator/parameters/parameters.component';
import { DailydataComponent } from '../statusindicator/dailydata/dailydata.component';
import { DisplayComponent } from '../statusindicator/display/display.component';
import { ExpandableModule } from '../expandable/expandable.module';
import { TranslateModule } from '@ngx-translate/core';
import { StatisticsComponent } from '../statusindicator/statistics/statistics.component';
import { HourlyDataDialogComponent } from '../statusindicator/hourlydatadialog/hourlydata-dialog.component';
import { DailyDataDialogComponent } from '../statusindicator/dailydatadialog/dailydata-dialog.component';
import { ParametersEditComponent } from '../statusindicator/parametersedit/parameters.edit.component';
import { ResponsiveModule } from 'ngx-responsive';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DailydataGraphComponent } from '../statusindicator/dailydatagraph/dailydatagraph.component';
import { BatteryModule } from '../battery/battery.module';
import { MetadataComponent } from '../statusindicator/metadata/metadata.component';
import { GsmSignalIndicatorModule } from '../gsmsignalindicator/gsmsignalindicator.module';
import { NgxLiquidGaugeModule } from 'ngx-liquid-gauge';
import {StatusindicatorComponent} from '@dmonitor/sensors/statusindicator/statusindicator.component';
import {StatusComponent} from '@dmonitor/sensors/statusindicator/status/status.component';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      SensorErrorModule,
      BatteryModule,
      GsmSignalIndicatorModule,
      SensorLoadingModule,
      NgxLiquidGaugeModule,
      TranslateModule.forChild(),
      ResponsiveModule.forRoot(),
      NgxChartsModule,
      ExpandableModule
    ],
    exports: [StatusindicatorComponent, ParametersEditComponent, StatusComponent],
    declarations: [
      StatusindicatorComponent,
      ParametersComponent,
      MetadataComponent,
      DailydataComponent,
      DisplayComponent,
      StatisticsComponent,
      DailyDataDialogComponent,
      DailydataGraphComponent,
      ParametersEditComponent,
      HourlyDataDialogComponent,
      StatusComponent
    ],
    entryComponents: [HourlyDataDialogComponent, DailyDataDialogComponent]
  })
  export class StatusindicatorModule {}
