import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController, NavParams, LoadingController} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@dmonitor/shared/index';
@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.pug',
  styleUrls: ['./user-dialog.component.sass'],
})
export class UserDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.new_user_dialog';
  private uid: string;
  private itemid: string;
  private aid: string;
  private priv: string;
  constructor(
    public modalController: ModalController, 
    toastController: ToastController,
    loadingController: LoadingController,
    translateService: TranslateService,
    private navParams: NavParams,    
    private userService: UserService,
    public formBuilder: FormBuilder) { 
      super(toastController,loadingController,translateService);
      this.userDataForm = formBuilder.group({
        email: ['', Validators.required],
        password: ['', Validators.required],
        passwordConfirm: ['', Validators.required]
      });
    }


  ngOnInit() {
    this.dismissLoading(1000);
  }
  public userDataForm: FormGroup ;
  public dismiss(success:Boolean) {
    this.modalController.dismiss(success);
  }

  public onClickSubmit(){
    var userData = this.userDataForm.value;
    if((userData.password != userData.passwordConfirm) || userData.password.length == 0 || userData.passwordConfirm.length == 0){
      this.showFailedToast(this.translate("msg_validation_error"));
      return;
    }else{
      this.presentLoadingWith(1000,() => {
        this.userService.changeUser(userData.email, null, userData.password).subscribe((res:any) => {
          this.dismissLoading();
          debugger;
          if(res.type == "result"){
            this.showSuccessToast(this.translate("msg_success"));
            this.dismiss(true);
          }else{
            this.showFailedToast(this.translate("msg_save_error"));
            
          }
        },err => {
          this.dismissLoading();
          this.showErrorToast(this.translate('msg_save_error'),err);          
        });  
      });
    }
  }



}
