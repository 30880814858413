import { Component, Input, OnInit } from '@angular/core';
import { BatteryLevel } from '@dmonitor/sensors/battery/battery.component';
import { EquipmentService } from '@dmonitor/services/equipment.service';
import { FinisherData, FinisherDeviceType, FinisherSetting } from '@dmonitor/shared/response/sensor/finisher';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

import { FinisherGroupedSensorService } from '../finishergroupedsensor.service';
import { OverlayEventDetail } from '@ionic/core';
import { FinisherEditDialogComponent } from '@dmonitor/dialogs/finishereditdialog/finisher-edit-dialog.component';

@Component({
    selector: 'sensor-finisher',
    templateUrl: 'finisher.component.pug',
    styleUrls: ['finisher.component.sass'],
})
export class FinisherComponent implements OnInit {
    @Input() Name: string;
    @Input() ThermometerNeeded: boolean = false;
    @Input() Privilage: string;
    @Input() Type:FinisherDeviceType;
    public Settings: FinisherSetting | null = null;
    public Data: FinisherData | null = null;
    public  Active: number;

    public Height: string;
    public expanded = true;
    public BatteryClass: string;
    public PicLink: string|null = null;
    public ErrorState: string = "";
    public PicContainerClass:string = "";
    public LastUpdateErrorClass: string = "";
    public TempDateErrorClass: string = "";
    constructor(
        private equipmentService: EquipmentService,
        private finisherGroupedSensorService: FinisherGroupedSensorService,
        private modalController: ModalController
        ) {

    }
    public ngOnInit(): void {
        this.finisherGroupedSensorService.dataReloaded.subscribe((val) => {
            if(val == false)return;
            switch(this.Type){
                case FinisherDeviceType.LEFT_DEVICE:
                    this.Settings = this.finisherGroupedSensorService.leftEquipment.Result.Settings;
                    this.Data = this.finisherGroupedSensorService.leftEquipment.Result.Data;
                    this.Active = this.finisherGroupedSensorService.leftEquipment.Result.Active;
                    this.Name = this.finisherGroupedSensorService.leftEquipment.Result.Name;
                    break;
                case FinisherDeviceType.MIDDLE_DEVICE:
                    this.Settings = this.finisherGroupedSensorService.middleEquipment.Result.Settings;
                    this.Data = this.finisherGroupedSensorService.middleEquipment.Result.Data;
                    this.Active = this.finisherGroupedSensorService.middleEquipment.Result.Active;
                    this.Name = this.finisherGroupedSensorService.middleEquipment.Result.Name;
                    break;
                case FinisherDeviceType.RIGHT_DEVICE:
                    this.Settings = this.finisherGroupedSensorService.rightEquipment.Result.Settings;
                    this.Data = this.finisherGroupedSensorService.rightEquipment.Result.Data;
                    this.Active = this.finisherGroupedSensorService.rightEquipment.Result.Active;
                    this.Name = this.finisherGroupedSensorService.rightEquipment.Result.Name;
                    break;
            } 
            this.setErrorState();
            if(this.Data != null && this.Data.t == null) this.ThermometerNeeded = false;
            if(this.ThermometerNeeded == false){
                this.PicContainerClass = "finisher-gauge-block-center";
            }
            if(this.Data != null){
                this.CalculateLevelFromPercent();
                this.setPictureLink();
            }
        });
    }
    public toggleTemperature(id) {
        this.equipmentService.changeFinisherTemperatureState(id).subscribe();
    }
    public togglePicture(id) {
        this.equipmentService.changeFinisherPictureState(id).subscribe();
    }
    public expandItem(item, event): void {
        if(event.target.tagName != 'ION-CARD-TITLE')return;
        if (this.expanded) {
            this.expanded = false;
        } else {
            this.expanded = true;
        }
    }
    public CalculateLevelFromPercent(): void {
        let battery = this.Data.battery;
        if (battery >= 50) {
            this.BatteryClass = "battery-green";
            return;
        }

        if (battery < 50) {
            this.BatteryClass = "battery-yellow";
            return;
        }

        if (battery <= 25) {
            this.BatteryClass = "battery-red";
            return;
        }
    }
    public setPictureLink(){
        if(this.Data.pic != null){
            this.PicLink = `${environment.apiUrl}/finisher/picture/${this.Data.pic}`;
        }
    }

    public setErrorState(){
        if(this.Settings?.is_data_collection == 0){
            this.ErrorState = "";
            this.LastUpdateErrorClass = "";
            this.TempDateErrorClass = "";
            return;
        }
        if(this.Data != null){
            let currentDate = new Date();
            currentDate.setHours(currentDate.getHours() - 1);
            this.ErrorState = "";
            this.LastUpdateErrorClass = "";
            this.TempDateErrorClass = "";
            if(this.Data.last_update != null){
                let date = new Date(this.Data.last_update);
                if(date < currentDate){
                    this.ErrorState = "finisher-no-new-data-error";
                    this.LastUpdateErrorClass = "finisher-date-error-color";
                }
            }
            if(this.Data.t_date != null){
                let date = new Date(this.Data.t_date);
                if(date < currentDate){
                    this.ErrorState = "finisher-no-new-data-error";
                    this.TempDateErrorClass = "finisher-date-error-color";
                }
            }
        }else if(this.Active == 1 && this.Data == null){
            this.ErrorState = "finisher-no-new-data-error";
        }
    }

    public async openFinisherEditDialog(e){
        
        const modal: HTMLIonModalElement =
        await this.modalController.create({
            component: FinisherEditDialogComponent,
            componentProps: {
                Settings: this.Settings
            }
        });
        

        await modal.present();
    }
    
}
