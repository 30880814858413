import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'sensor-error',
    templateUrl: 'sensorerror.component.pug',
    styleUrls: [ 'sensorerror.component.sass'],
  })
  export class SensorErrorComponent implements OnInit{
    @Input() public error:HttpErrorResponse = null;
    @Output() public onReload = new EventEmitter<boolean>();
    public reload():void{
        this.onReload.emit(true);
    }
    public ngOnInit(): void {
        const e:any = this.error
        if(e.error != null && e.error.error != null && e.error.error.result != null){
            console.error(e.error.error.result)
            return;
        }
        console.error(this.error.message);
    }
  }