import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, ToastController, LoadingController, IonRefresher } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@dmonitor/services/index';
import { BaseComponent } from '@dmonitor/shared/index';
import { SensorsType } from '@dmonitor/sensors/index';

@Component({
  selector: 'app-log-dialog',
  templateUrl: './log-dialog.component.pug',
  styleUrls: ['./log-dialog.component.sass'],
})
export class LogDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.logfile_dialog';
  @Input() fileName: string;
  @Input() type: string;
  public SensorsTypes: typeof SensorsType = SensorsType;
  public LogNotFound:boolean = false;
  public title = '';
  public Items = []; 
  public Count = 0;
  public IsLinesOverflow:boolean = false;
  public filterTerm: string = '';

  @ViewChild(IonRefresher) public Refresher: IonRefresher;


  constructor(
    public modalController: ModalController,
    translateService: TranslateService,
    loadingController: LoadingController,
    private dataService: DataService,
    toastController: ToastController
  ) {
    super(toastController, loadingController, translateService);
  }


  public ngOnInit() {
    this.dismissLoading(1000);


    this.title = this.translate(`${this.type}_title`) + this.fileName;
    this.LoadList();
    
  }
  protected LoadList(): void {
    this.dataService.LogService.GetLogFile(this.type,this.fileName).subscribe(res => {
      this.Items = res.result.data;
      this.Count = res.result.count;
      if(res.result.data.length < res.result.count){
        this.IsLinesOverflow = true;
      }else{
        this.IsLinesOverflow = false;
      }
      this.$initialized.next(true);
      this.dismissLoading(500);
      this.refreshComplete();
    }, err => {
      this.$initialized.next(true);
      if(err.status == 404){
        this.LogNotFound = true;
        return;
      }
      this.showErrorToast("Error:", err);
      this.dismissLoading(500);
    });

  }
  protected onRefresh(): void {
    this.LoadList();
  }

  protected refreshComplete(): void {
    if (this.Refresher == null) {
      return;
    }
    this.Refresher.complete();

  }
  protected doRefresh(): void {
    this.onRefresh();
    setTimeout(() => {
      this.refreshComplete();
    }, 5000);
  }
  public dismiss(success: Boolean) {
    this.modalController.dismiss(success);
  }

  public openDebug(data:string):void{
    let dummy = document.createElement("input");
    dummy.style.display = 'none';
    document.body.appendChild(dummy);
    let copyData = data[0];
    debugger;
    dummy.setAttribute("id", "dummy_id");
    dummy.setAttribute("value", copyData);
    dummy.select();
    dummy.setSelectionRange(0, 99999)
    document.execCommand("copy");
    document.body.removeChild(dummy);
    debugger;
  }
  public copy(textarea_id):void{
    debugger;
    let dummy = document.getElementById(`txt_${textarea_id}`) as HTMLInputElement;
    dummy.select();
    dummy.setSelectionRange(0, 99999)
    document.execCommand("copy");
    debugger;
  }

}
