// 
import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   lat,long | googlegps
 * Example:
 *   {{ 47.4979,19.1058 | googlegps }}
 *   formats to: https://www.google.com/maps/search/?api=1&query=47.4979,19.1058
*/
@Pipe({name: 'googlegps'})
export class GoogleGpsPipe implements PipeTransform {
    //lat,long
  transform(value: string): string {
    return `https://www.google.com/maps/search/?api=1&query=${value}`;
  }
}