import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams, AlertController, ToastController, NavController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService, CountyService, DataService } from '@dmonitor/services/index';
import { BaseComponent } from '@dmonitor/shared/index';
@Component({
  selector: 'app-more-button',
  templateUrl: './more-button.component.pug',
  styleUrls: ['./more-button.component.sass'],
})
export class MoreButtonComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.sites';
  public hasRoleForEdit : Boolean = false;
  constructor(
    translateService: TranslateService,
    toastController: ToastController,
    loadingController: LoadingController,
    private navParams: NavParams,
    private alertController: AlertController,
    private navigateCtr:NavController,
    private dataService: DataService,
    private popoverController: PopoverController) {
      super(toastController,loadingController,translateService);

  }
  ngOnInit() {
    this.hasRoleForEdit = this.navParams.data.page.HasRoleForEdit;
  }

  public openModal(){
    this.navParams.data.page.openCountyEditModal();
    this.popoverController.dismiss();
  }

  public reload(){
    document.location.href='/';
  }

  public async delete(){
    let header = 'h';
    let subHeader = 's';
    let message = 'mesg';
    let key = 'delete_confirmation_dialog';
    let buttons = [
      {
        text: this.translate(`${key}.button_cancel`),
        role: 'cancel',
        
        handler: () => {
          this.popoverController.dismiss();          
        }
      }, {
        text: this.translate(`${key}.button_ok`),
        cssClass: 'dm-secondary',
        color: "danger",
        handler: () => {
          this.dataService.CountyService.delete(this.dataService.CountyService.GetSelected()).subscribe(res => {
            this.dataService.LoadCounties();
            this.showSuccessToast(this.translate(`${key}.msg_success`));
            this.popoverController.dismiss();
            this.navigateCtr.back();
          },err => {
            this.showErrorToast(this.translate(`${key}.msg_error`),err);
            this.popoverController.dismiss();
          });
        }
      }
    ];
    
    header = this.translate(`${key}.header`);
    subHeader = this.translate(`${key}.sub_header`);
    message = this.translate(`${key}.msg`);            
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: buttons
    });
    await alert.present();
  }



}
