import { SensorErrorComponent } from './sensorerror.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      TranslateModule.forChild(),
      IonicModule,
    ],
    exports: [SensorErrorComponent],
    declarations: [SensorErrorComponent]
  })
  export class SensorErrorModule {}