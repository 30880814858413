import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@dmonitor/shared/index';
import { ToastController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DeviceColors, DeviceType } from '@dmonitor/sensors/sensors.type';
import {EquipmentService} from '@dmonitor/services/equipment.service';
import {HygrometerDailyData, HygrometerSetting} from '@dmonitor/shared/response/sensor/hygrometer';

@Component({
  selector: 'hygrometer-hourlydatagraph',
  templateUrl: 'hourlydatagraph.component.pug',
  styleUrls: ['hourlydatagraph.component.sass'],
})
export class HourlydataGraphComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = "page.equipment.hygrometer.hourlydata.graph";
  @Input() DailyData: HygrometerDailyData[] = [];
  @Input() Settings: HygrometerSetting;
  @Input() Day: string;
  @Input() EquipmentId: string;
  showXAxis = true;
  showYAxis = true;
  xAxisLabel = '';
  yAxisLabel = '';
  thermoGraphData = [];
  hygroGraphData = [];
  graphData = null;
  thermoColorScheme = {
    domain: [DeviceColors.COLD_GRAPH_COLOR, DeviceColors.HOT_WATER_TEXT_COLOR]
  };
  hygroColorScheme = {
    domain: [DeviceColors.HOT_WATER_TEXT_COLOR]
  };
  constructor(
    toastController: ToastController,
    loadingController: LoadingController,
    private equipmentService: EquipmentService,
    translateService: TranslateService) {
    super(toastController, loadingController, translateService);
  }
  public ngOnInit(): void {

        this.xAxisLabel = this.translate('xlabel');
        this.yAxisLabel = this.translate('ylabel');
      this.thermoGraphData = [];
      this.hygroGraphData = [];
      this.DailyData.forEach(d => {
          if(d.t != '-') {
              this.thermoGraphData.push({
                  name: d.from,
                  value: Number(d.t)
              });
          }
          if (d.h != '-') {
              this.hygroGraphData.push({
                  name: d.from,
                  value: Number(d.h)
              });
          }
      });

      this.thermoGraphData = [
          {
              "name":"Hőmérséklet",
              "series":this.thermoGraphData,
          }
      ];
      this.hygroGraphData = [
          {
              "name":"Páratartalom",
              "series":this.hygroGraphData,
          },

      ];
      this.thermoGraphData = [...this.thermoGraphData];
      this.hygroGraphData = [...this.hygroGraphData];


  }
  public chartLabel(val){
      val = Number(val);
      if(val== 0 ||val%4==0){
          return val
      }
      return '';
  }
    public ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
        this.thermoGraphData = [];
        this.hygroGraphData = [];
        this.DailyData.forEach(d => {
            if(d.t != '-') {
                this.thermoGraphData.push({
                    name: d.from,
                    value: Number(d.t)
                });
            }
            if (d.h != '-') {
                this.hygroGraphData.push({
                    name: d.from,
                    value: Number(d.h)
                });
            }
        });

        this.thermoGraphData = [
            {
                "name":"Hőmérséklet",
                "series":this.thermoGraphData,
            }
        ];
        this.hygroGraphData = [
            {
                "name":"Páratartalom",
                "series":this.hygroGraphData,
            },

        ];
        this.thermoGraphData = [...this.thermoGraphData];
        this.hygroGraphData = [...this.hygroGraphData];


    }
}
