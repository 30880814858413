import { NgModule } from '@angular/core';
import { IntegratorModule, IntegratorComponent } from './integrator/index';
import { SensorErrorComponent } from './sensorerror/sensorerror.component';
import {ThermometerModule, ThermometerComponent} from '@dmonitor/sensors/thermometer';
import {HygrometerComponent, HygrometerModule} from '@dmonitor/sensors/hygrometer';
import {StatusindicatorModule} from '@dmonitor/sensors/statusindicator/statusindicator.module';
import {StatusindicatorComponent} from '@dmonitor/sensors/statusindicator/statusindicator.component';
import {TankComponent, TankModule} from '@dmonitor/sensors/tank';
import {GroupedSensorComponent, GroupedSensorModule} from '@dmonitor/sensors/groupedsensor';
import {FinisherGroupedSensorModule, FinisherGroupedSensorComponent} from '@dmonitor/sensors/finishergroupedsensor';
@NgModule({
    imports: [
        IntegratorModule,
        ThermometerModule,
        HygrometerModule,
        StatusindicatorModule,
        TankModule,
        GroupedSensorModule,
        FinisherGroupedSensorModule
    ],
    declarations: [],
    exports: [IntegratorComponent, ThermometerComponent, HygrometerComponent, StatusindicatorComponent, TankComponent, GroupedSensorComponent, FinisherGroupedSensorComponent],

  })
  export class SensorsModule {}
