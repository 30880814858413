import { BaseComponent } from './base.component';
import { IonRefresher, LoadingController, ToastController } from '@ionic/angular';
import { ViewChild, Directive, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../services/data.service';
import { filter } from 'rxjs/operators';
import { ListItemDto } from './dto/listitem.dto';
import { BehaviorSubject } from 'rxjs';

@Directive()
export abstract class BaseListComponent extends BaseComponent {
  public abstract BackLink: string;
  protected $refreshList = null;
  protected refreshListSubscriberInited: boolean = false;
  protected abstract onRefresh(): void;
  public abstract onItemSelect(item: ListItem): void;
  protected abstract setUserRights(): void;
  protected abstract onListInit(): void;
  protected abstract isListReadyForRender():boolean;
  @ViewChild(IonRefresher) public Refresher: IonRefresher;
  public Items: Array<{ title: string; note: string; icon: string, url: string, id: string }> = null;
  public PageTitle: string = "";
  public ListTitle: string = "";
  public HasRoleForEdit: Boolean = false;
  public HasRoleForAdd: Boolean = false;
  public IsSuperadmin: boolean = false;
  public IsSuperadminMode: boolean = false;
  public Url: string;
  public IsHome: boolean;
  constructor(
    toastController: ToastController,
    loadingController: LoadingController,
    translateService: TranslateService,
    protected dataService: DataService
  ) {
    super(toastController, loadingController, translateService);
    this.dataService.$IsSuperadminMode.subscribe(superadminMode=>{
      this.IsSuperadminMode = superadminMode;
      this.setUserRights();
      if(this.$refreshList == null){
        return;
      }
      //debugger;
      this.$refreshList.next(true);
    });
    
  }

  public ionViewWillEnter(): void {
    console.log(`${this.TRANSLATE_KEY}: ionViewWillEnter()`);
    
    if (this.refreshListSubscriberInited == false) {
      return;
    }
    //debugger;
    this.$refreshList.next(true);
  }

  protected initList(): void {
    console.log(`${this.TRANSLATE_KEY}: initList()`);
    this.dataService.UserService.Inited().subscribe(IsInited => {
      this.IsSuperadmin = this.dataService.UserService.IsUserSuperadmin();
      this.setUserRights();
      this.dataService.IsInited.pipe(filter(v => v == true)).subscribe(isinited => {
        
        if (this.refreshListSubscriberInited) {
          return;
        }
       
        this.refreshListSubscriberInited = true;
        console.log(`${this.TRANSLATE_KEY}: sunscribe refreshList`);
        this.$refreshList = new BehaviorSubject(false)
        this.$refreshList.pipe(filter(v => v == true)).subscribe(isinited => {
          //debugger;
          try{
            if(this.isListReadyForRender() == false){
              return;
            }
            if (this.IsSuperadminMode && this.IsSuperadminMode) {
             
              this.onSuperadminListInit();
            } else {
              this.onListInit();
            }
  
          }catch(e){
            debugger;
          }
        });
        this.$refreshList.next(true);
      });

    });

  }

  protected createItems(data: ListItemDto[], baseUrl: string): ListItem[] {
    const items = [];
    data.forEach((item: ListItemDto) => {
      items.push({
        title: item.name,
        url: `${baseUrl}/${item.id}`,
        icon: 'arrow-forward',
        id: item.id
      });
    });
    items.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
    return items;
  }

  protected onSuperadminListInit(): void {
    this.onListInit();
  }

  protected refreshComplete(): void {
    if (this.Refresher == null) {
      return;
    }
    this.Refresher.complete();

  }

  protected onSuperadminRefresh(): void {
    this.onRefresh();
  }
  protected doRefresh(timeout = 5000): void {
    this.$initialized.pipe(filter(v => v == true)).subscribe(isinited => {
      if (this.IsSuperadminMode && this.IsSuperadminMode) {
        this.onSuperadminRefresh();
      } else {
        this.onRefresh();
      }
    });


    setTimeout(() => {
      this.refreshComplete();
    }, 5000);
  }
  public superadminModeOn(): void {
    this.$refreshList.next(true);
  }
  public superadminModeOff(): void {
    this.$refreshList.next(true);
  }
}
export interface ListItem {
  title: string;
  note: string;
  icon: string,
  url: string,
  id: string
}