import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private cookieName = "token";
    public $IsLoggedIn = new BehaviorSubject<boolean>(false);
    constructor(
        private http: HttpClient,
        private cookieService: CookieService
    ) {

    }
    public login(userName: string, password: string) {


        const postData = JSON.stringify({
            email: userName,
            password: password
        });

        return this.http.post<object>(`${environment.apiUrl}/login?apikey=${environment.apiKey}`, postData, this.GetHttpOptions()).pipe(
            map((res: any) => {
                this.cookieService.set(this.cookieName, res.result.token);
                environment.token = res.result.sessionid;
                console.log(`TOKEN SET: ${environment.token}`);
                this.$IsLoggedIn.next(true);
                return res;
            })
        );
    }

    public logout() {
        this.cookieService.delete(this.cookieName);
        environment.token = "";
        this.$IsLoggedIn.next(false);
        return this.http.get<any>(`${environment.apiUrl}/logout`);
    }


    protected GetHttpOptions() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                //'XAuth-Token': environment.sessionId
            })
        };
        return httpOptions;
    }


}  