import { NgModule } from '@angular/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AboutPageModule } from './about/about.module';
import { ChangepasswordPageModule } from './changepassword/changepassword.module';
import { CompanyDialogModule } from './dialogs/companydialog/company-dialog.module';
import { CountyDialogModule } from './dialogs/countydialog/county-dialog.module';
import { RighteditDialogModule } from './dialogs/rightedit/rightedit-dialog.component.module';
import { SiteDialogModule } from './dialogs/sitedialog/site-dialog.module';
import { UserDialogModule } from './dialogs/userdialog/user-dialog.component.module';
import { RightsPageModule } from './rights/rights.module';
import { SensorsModule } from './sensors';
import { UserPageModule } from './user/user.module';
import { CompaniesPageModule } from './companies/companies.module';
import { SitesPageModule } from './sites/sites.module';
import { EquipmentModule } from './equipment/equipment.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptor } from './shared/auth.interceptor';
import { NgxChartsModule } from '@swimlane/ngx-charts';

function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    NgxChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

    AppRoutingModule,
    HttpClientModule,
    AboutPageModule,
    ChangepasswordPageModule,
    CompanyDialogModule,
    CountyDialogModule,
    RighteditDialogModule,
    SiteDialogModule,
    UserDialogModule,
    SitesPageModule,
    RightsPageModule,
    SensorsModule,
    EquipmentModule,
    UserPageModule,
    CompaniesPageModule

  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
