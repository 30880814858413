import { SensorLoadingComponent } from './sensorloading.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
    ],
    exports: [SensorLoadingComponent],
    declarations: [SensorLoadingComponent]
  })
  export class SensorLoadingModule {}