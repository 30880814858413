import { Injectable } from '@angular/core';
import { FinisherGroupedSensorEquipment, FinisherGroupedSensorResponse } from '@dmonitor/shared/response/sensor/finishergroupedsensor';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FinisherGroupedSensorService {
    public dataReloaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public leftEquipment: FinisherGroupedSensorEquipment | null = null;
    public middleEquipment: FinisherGroupedSensorEquipment | null = null;
    public rightEquipment: FinisherGroupedSensorEquipment | null = null;
    constructor() { }

}