import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { Equipment, PrivilegesEnum } from '@dmonitor/shared/dto';
import { IonRefresher, LoadingController } from '@ionic/angular';
import { EquipmentService, DataService, UserService } from '@dmonitor/services/index';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BatteryLevel } from '../battery/battery.component';
import { SensorPowerSupply } from '../sensors.type';
import { PowerSupply } from '../powersupply/powersupply';
import { SensorsType } from '@dmonitor/sensors/index';

import {
    FinisherGroupedSensorEquipment,
    FinisherGroupedSensorParameters,
    FinisherGroupedSensorResponse,
    MetadataBlock
} from '@dmonitor/shared/response/sensor/finishergroupedsensor';
import { FinisherDeviceType } from '@dmonitor/shared/response/sensor/finisher';
import { FinisherGroupedSensorService } from './finishergroupedsensor.service';

@Component({
    selector: 'sensor-finishergroupedsensor',
    templateUrl: 'finishergroupedsensor.component.pug',
    styleUrls: ['finishergroupedsensor.component.sass'],
})
export class FinisherGroupedSensorComponent implements OnInit {
    @Input() public equipment: Equipment;
    @Input() public refreshEvent: Subject<void>;
    @Input() public silentRefreshEvent: Subject<void>;
    @ViewChild(IonRefresher) public refresher: IonRefresher;
    public equipmentData: FinisherGroupedSensorResponse;
    public leftEquipment: FinisherGroupedSensorEquipment | null = null;
    public middleEquipment: FinisherGroupedSensorEquipment | null = null;
    public rightEquipment: FinisherGroupedSensorEquipment | null= null;

    
    public Type: number;
    public EquipmentPeriod: number = 0;
    public ServerPeriod: number = 0;
    public IsError: boolean = false;
    public IsLoading: boolean = false;
    public IsInit: boolean = true;
    public IsRefresh: boolean = false;
    public LoadDataInprogress: boolean = false;
    public Error: Error = null;
    public BatteryLevel = BatteryLevel.LNULL;
    public IsParameterView = false;
    public IsStatView = false;
    public IsMetadataView = false;
    public IsMonitoringView = false;
    public IsSimpleView = false;
    public IsAdvancedView = false;
    public IsMonthlyGraphStatView = false;
    public IsDailyGraphStatView = false;
    public IsReadingsView = false;
    public SensorPowerSupply = SensorPowerSupply;
    public loadingDialog;
    public SensorsTypes: typeof SensorsType = SensorsType;
    public Privilage: string;
    public DeviceTypes = FinisherDeviceType;
    
    public DataCollectionState: number = 0;
    constructor(
        public loadingController: LoadingController,
        private dataService: DataService,
        private translateService: TranslateService,
        private equipmentService: EquipmentService,
        private userService: UserService,
        private finisherGroupedSensorService: FinisherGroupedSensorService
    ) {

    }
    private translate(title: string): string {
        return this.translateService.instant(`page.equipment.finishergroupedsensor.${title}`);
    }

    public async ngOnInit() {
        this.loadingDialog = await this.loadingController.create({
            message: this.translate('dialog_loading_msg')
        });
        this.silentRefreshEvent.subscribe(() => {
            if (this.LoadDataInprogress == false) {
                this.setEquipmentData();
            }
        });
        this.refreshEvent.subscribe(() => {
            if (this.LoadDataInprogress == false) {
                this.IsLoading = true;
                this.setEquipmentData();
            }
        });
        this.setEquipmentData();
    }

    public reload(): void {
        this.IsError = false;
        this.Error = null;
        this.setEquipmentData();
    }

    public doRefresh() {
        this.IsRefresh = true;
        //this.dataService.LoadEquipments();
        setTimeout(() => {
            this.setEquipmentData();
        }, 200);

    }
   
    private setEquipmentData() {
        console.log('setEquipmentdata');
        this.LoadDataInprogress = true;
        if (this.loadingDialog != null && this.IsLoading) {
            this.loadingDialog.present();
        }
        if (this.equipment.serial_num?.indexOf("/") == -1) {
            this.equipment.serial_num = this.equipment.serial_num.slice(0, 3) + "/" + this.equipment.serial_num.slice(3);
        }
        this.IsParameterView = this.dataService.LoggedInUser.viewSettings.enableParameters;
        this.IsStatView = this.dataService.LoggedInUser.viewSettings.enableStat;
        this.IsMetadataView = this.dataService.LoggedInUser.viewSettings.enableMetadata;
        this.IsAdvancedView = this.dataService.LoggedInUser.viewSettings.enableAdvancedview;
        this.IsMonthlyGraphStatView = this.dataService.LoggedInUser.viewSettings.enableMonthlyGraphStatview;
        this.IsDailyGraphStatView = this.dataService.LoggedInUser.viewSettings.enableDailyGraphStatview;
        this.IsSimpleView = this.dataService.LoggedInUser.viewSettings.enableSimpleview;
        this.IsMonitoringView = this.dataService.LoggedInUser.viewSettings.enableMonitoringview;
        this.IsReadingsView = this.dataService.LoggedInUser.viewSettings.enableReadingsview;
        this.Privilage = (this.dataService.Equipments.find((x) => x.id == this.equipment.id)).privilege_id;
        
        this.finisherGroupedSensorService.dataReloaded.next(false);
        this.equipmentService.getFinisherGroupedSensorData(this.equipment.id).subscribe(
            (res) => {
                this.equipmentData = res.result;
                
                if(this.equipmentData != null && this.equipmentData.equipments.length>0){
                    let firstEq = this.equipmentData.equipments[0];
                    this.DataCollectionState = firstEq.Result.Settings.is_data_collection;
                    this.equipmentData.equipments.forEach((val) => {
                        switch(val.Result.Settings.device_type){
                            case FinisherDeviceType.LEFT_DEVICE:
                                this.leftEquipment = val;
                                this.finisherGroupedSensorService.leftEquipment = val;
                                break;
                            case FinisherDeviceType.MIDDLE_DEVICE:
                                this.middleEquipment = val;
                                this.finisherGroupedSensorService.middleEquipment = val;
                                break;
                            case FinisherDeviceType.RIGHT_DEVICE:
                                this.rightEquipment = val;
                                this.finisherGroupedSensorService.rightEquipment = val;
                                break;
                        } 
                    });
                    
                }

                this.IsError = false;

                this.IsInit = false;
                var deltaServer = 0;
                var deltaEquipment = 0;

                if (deltaServer > 1) {
                    this.ServerPeriod = deltaServer;
                }
                console.log('ServerPeriod: ' + this.ServerPeriod);
                if (deltaEquipment > 1) {
                    this.EquipmentPeriod = deltaEquipment;
                }
                console.log('EquipmentPeriod: ' + this.EquipmentPeriod);
                this.finisherGroupedSensorService.dataReloaded.next(true);
                this.LoadDataInprogress = false;
                this.loadingDialogDismiss(500);
                this.ionRefresherComplete(500);

            },
            (err) => {
                this.IsError = true;
                this.IsInit = false;
                this.Error = err;
                this.LoadDataInprogress = false;
                this.loadingDialogDismiss()
                this.ionRefresherComplete();
            }
        );
    }

    private loadingDialogDismiss(timeout: number = 0): void {
        this.IsLoading = false;
        if (this.loadingDialog == null) {
            return;
        }
        setTimeout(() => {
            this.loadingDialog.dismiss();
        }, timeout);
    }

    private ionRefresherComplete(timeout: number = 0): void {
        this.IsRefresh = false;
        if (this.refresher == null) {
            return;
        }
        setTimeout(() => {
            this.refresher.complete();
        }, timeout);

    }
}
