import { Component, Input, OnInit } from '@angular/core';
import { IntegratorData, IntegratorSetting, DailyData, MonthlyData } from '@dmonitor/shared/response/sensor/integrator';
import { BaseComponent } from '@dmonitor/shared/index';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'integrator-dailydata-dialog',
  templateUrl: 'dailydata-dialog.component.pug',
  styleUrls: ['dailydata-dialog.component.sass'],
})
export class DailyDataDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = "page.equipment.integrator.dailydata";
  @Input() Settings: IntegratorSetting;
  @Input() DailyData: DailyData[] = [];
  @Input() MonthlyData: MonthlyData[] = [];
  constructor(
    public modalController: ModalController,
    toastController: ToastController,
    loadingController: LoadingController,
    translateService: TranslateService) {
    super(toastController, loadingController, translateService);
  }

  public ngOnInit(): void {
    console.log(this.MonthlyData);
  }
  public parseInt(value:string):Number{
    return parseInt(value);
  }

  public dismiss() {
    this.modalController.dismiss();
  }

}