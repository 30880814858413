import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UrlHelper } from '../shared';
import { DataService } from './data.service';
import { PrivilegeArea, PrivilegesEnum } from '@dmonitor/shared/dto';
@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private home: string = "";
  private isInitedSubject = new BehaviorSubject(false);
  public CompanyIdFromRoute: string;
  public CountyIdFromRoute: string;
  public SiteIdFromRoute: string;
  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }
  get IsInited(): BehaviorSubject<boolean> {
    return this.isInitedSubject;
  }

  public calculateHome(): void {
    this.home = `/${UrlHelper.URL_EQUIPMENTS_LIST}`
    this.isInitedSubject.next(true);
    return;
    this.CompanyIdFromRoute = this.route.snapshot.paramMap.get("companyId");
    this.CountyIdFromRoute = this.route.snapshot.paramMap.get("countyId");
    this.SiteIdFromRoute = this.route.snapshot.paramMap.get("siteId");

    //debugger;
    if (this.dataService.IsCompaniesEmpty) {
      debugger;

      this.home = `/${UrlHelper.URL_COMPANIES}`;
      this.isInitedSubject.next(true);
      return;

    }
    if (this.dataService.Companies.length > 1 || this.dataService.UserService.IsUserSuperadmin()) {
      this.home = `/${UrlHelper.URL_COMPANIES}`;
      this.isInitedSubject.next(true);
      return;
    }
    const company = this.dataService.Companies[0];
    const countiesForCompany = this.dataService.Counties.filter(c => c.company_id == company.id);
    debugger;
    if (countiesForCompany == null) {
      return;
    }
    if (countiesForCompany.length == 0){
      this.home = `/${UrlHelper.URL_COMPANIES}`;
      this.isInitedSubject.next(true);
      return;
    }
    if (countiesForCompany.length > 1 || Number(company.privilege_id) == PrivilegesEnum.EDIT) {
      this.home = `/${UrlHelper.URL_COUNTIES}/${company.id}`;
      this.isInitedSubject.next(true);
      return;

    }
    const county = countiesForCompany[0];
    const sitesForCounty = this.dataService.Sites.filter(s => s.county_id == county.id);
    debugger;
    if (sitesForCounty == null) {
      return;
    }
    if(sitesForCounty.length == 0){
      this.home = `/${UrlHelper.URL_COUNTIES}/${company.id}`;
        this.isInitedSubject.next(true);
    }
    if (sitesForCounty.length > 1 || Number(county.privilege_id) == PrivilegesEnum.EDIT) {
      this.home = `/${UrlHelper.URL_SITES}/${company.id}/${county.id}`;
      this.isInitedSubject.next(true);
      return;
    }
    const site = sitesForCounty[0];
    const equipmentsForSite = this.dataService.Equipments.filter(e => e.site_id == site.id);

    if (equipmentsForSite == null) {
      return;
    }
    this.home = `/${UrlHelper.URL_EQUIPMENTS}/${company.id}/${county.id}/${site.id}`;

    if (equipmentsForSite.length > 1) {
      this.isInitedSubject.next(true);
      return;
    }
    if (equipmentsForSite.length == 0) {
      this.isInitedSubject.next(true);
      return;
    }
    if (equipmentsForSite.length == 1) {
      const equipmentId = equipmentsForSite[0].id;
      this.home = `/${UrlHelper.URL_EQUIPMENT}/${company.id}/${county.id}/${site.id}/${equipmentId}`;
      this.isInitedSubject.next(true);
      return;
    }
    //debugger;
  }

  public navigateHome(): void {
    //debugger;
    this.router.navigate([this.home]);
  }

  public navigateLogin(): void {
    this.router.navigate([`/${UrlHelper.URL_LOGIN}`]);
  }


  public getHome(): string {
    return this.home;
  }

  public isHome(page: string): boolean {
    console.log('IS HOME');
    console.log(`HOME: ${this.home}`);
    if (page.charAt(0) != '/') {
      page = `/${page}`;
    }
    console.log(`PAGE: ${page}`);
    return this.home == page;
  }
}
