import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import * as gauges from 'canvas-gauges';
import {FinisherData, FinisherSetting} from '@dmonitor/shared/response/sensor/finisher';

@Component({
  selector: 'finisher-lineargauge',
  templateUrl: 'lineargauge.component.pug',
  styleUrls: ['lineargauge.component.sass'],
})
export class LineargaugeComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() Data: FinisherData;
  @Input() Settings: FinisherSetting;
  @Input() Width: number = 120;
  @Input() Height: number = 300;
  @ViewChild("canvasgauge", { read: ElementRef }) canvasEl: ElementRef;
  public IsError = false;
  public ngOnInit(): void {

  }

  public ngAfterViewInit(): void {
    if (this.canvasEl != null) {
      this.createRadialGauge();

    }

  }
  public ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (this.canvasEl != null) {
      this.createRadialGauge();

    }

  }

  private createRadialGauge() {
    var gaugesLib = gauges;
    
    var radialGauge = new gaugesLib.LinearGauge({
      width: this.Width,
      height: this.Height,
      units: "°C",
      renderTo: this.canvasEl.nativeElement,
      title: "Hőmérséklet",
      minValue: this.Settings.min_temp_tick,
      maxValue: this.Settings.max_temp_tick,
      majorTicks: this.Settings.gauge_ticks,
      minorTicks: 5,
      strokeTicks: true,
      ticksWidth: 15,
      ticksWidthMinor: 7.5,
      highlights: [{"from":this.Settings.gauge_warn_min,"to":this.Settings.gauge_warn_max, "color": "rgba(200, 50, 50, .75)"}],
      colorMajorTicks: "#ffe66a",
      colorMinorTicks: "#ffe66a",
      colorTitle: "#000000",
      colorUnits: "#131313",
      colorNumbers: "#070707",
      colorPlate: "#ffffff00",
      colorPlateEnd: "#ffffff00",
      borderShadowWidth: 0,
      borders: false,
      borderRadius: 10,
      needleType: "arrow",
      needleWidth: 3,
      animationDuration: 1500,
      animationRule: "linear",
      colorNeedle: "#222",
      colorNeedleEnd: "",
      colorBarProgress: "#f08680",
      colorBar: "#dcdcdc",
      barStroke: 0,
      barWidth: 8,
      barBeginCircle: false,
      value: this.Data != null ? this.Data.t : 0,
      valueText: this.Data != null ? this.Data.t : 0
    }).draw();
  }
}
