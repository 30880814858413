import { Component, Input, OnInit } from '@angular/core';
import { EquipmentService } from '@dmonitor/services/equipment.service';
import { FinisherDeviceType } from '@dmonitor/shared/response/sensor/finisher';
import { FinisherDailyData, FinisherGroupedSensorDailyDataResult } from '@dmonitor/shared/response/sensor/finishergroupedsensor';
import { environment } from 'src/environments/environment';
import { FinisherGroupedSensorService } from '../finishergroupedsensor.service';
import { AlertController } from "@ionic/angular";

@Component({
    selector: 'finisher-storeddata',
    templateUrl: 'finisherstoreddata.component.pug',
    styleUrls: ['finisherstoreddata.component.sass'],
})
export class FinisherStoredDataComponent implements OnInit {
    @Input() GroupId: string;
    public Height: string;
    public expanded = false;
    public DailyData:FinisherDataRow[];
    public Date = this.formatDate(new Date());
    public Col1Name:string;
    public Col2Name:string;
    public Col3Name:string;
    
    constructor(private equipmentService: EquipmentService, private finisherGroupedSensorService: FinisherGroupedSensorService,public alertController: AlertController) {

    }
    public async showAlert(description) {
        const alert = await this.alertController.create({
          header: "Megjegyzés",
          message: description,
          buttons: ["OK"],
        });
    
        await alert.present();
    }
    public pictureLink(id){
        return `${environment.apiUrl}/finisher/picture/${id}`;
    }
    public ngOnInit(): void {
        this.loadDailyData();
    }
    
    public expandItem(item,event): void {
        if(event.target.tagName != 'ION-CARD-TITLE')return;
        if (this.expanded) {
            this.expanded = false;
        } else {
            this.expanded = true;
        }
    }

    public reloadTable(){
        this.loadDailyData(true);
    }

    public loadDailyData(isDateSet = false){
        if(isDateSet == false){
            this.Date = this.formatDate(new Date());
        }
        this.DailyData = [];
        this.Col1Name = this.finisherGroupedSensorService.leftEquipment?.Result?.Name??"page.equipment.finisher_grouped_sensor.left_device";
        this.Col2Name = this.finisherGroupedSensorService.middleEquipment?.Result?.Name??"page.equipment.finisher_grouped_sensor.middle_device";
        this.Col3Name = this.finisherGroupedSensorService.rightEquipment?.Result?.Name??"page.equipment.finisher_grouped_sensor.right_device";
        this.equipmentService.getFinisherGroupDailyData(this.GroupId, this.Date).subscribe(
            (res) => {
                if(res.result.daily.length>0){
                    let rows:FinisherDataRow[] = [];
                    res.result.daily.forEach((value) => {
                        let col1 = null;
                        let col2 = null;
                        let col3 = null;
                        let packageId = null;
                        let description = null;
                        value.data.forEach((pack) => {
                            switch(pack.device_type){
                                case FinisherDeviceType.LEFT_DEVICE:
                                    col1 = pack;
                                    packageId = pack.package_id;
                                    if(pack.description){
                                        description = pack.description;
                                    }
                                    break;
                                case FinisherDeviceType.MIDDLE_DEVICE:
                                    col2 = pack;
                                    packageId = pack.package_id;
                                    if(pack.description){
                                        description = pack.description;
                                    }
                                    break;
                                case FinisherDeviceType.RIGHT_DEVICE:
                                    col3 = pack;
                                    packageId = pack.package_id;
                                    if(pack.description){
                                        description = pack.description;
                                    }
                                    break;
                            }
                        });
                        let row = new FinisherDataRow();
                        row.col1 = col1;
                        row.col2 = col2;
                        row.col3 = col3;
                        row.packageid = packageId;
                        row.description = description;
                        rows.push(row);
                    });
                    this.DailyData = rows;
                }
            }
        );
    }
    private formatDate(date: Date) {
        return (
          [
            date.getFullYear(),
            this.padTo2Digits(date.getMonth() + 1),
            this.padTo2Digits(date.getDate()),
          ].join('-')
        );
      }

    private padTo2Digits(num: number) {
        return num.toString().padStart(2, '0');
    }
}
export class FinisherDataRow{
    public packageid: string |null = "";
    public description: string|null = "";
    public col1:FinisherDailyData|null = null;
    public col2:FinisherDailyData|null = null;
    public col3:FinisherDailyData|null = null;
}
