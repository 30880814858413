import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

export class ApiService {
    protected http: HttpClient;
    protected cookiService: CookieService
    protected get(url: string) {
        const httpOptions = {
          headers: this.getHttpHeaders()
          //withCredentials: true //this is required so that Angular returns the Cookies received from the server. The server sends cookies in Set-Cookie header. Without this, Angular will ignore the Set-Cookie header
        };
        return this.http.get<any>(`${environment.apiUrl}${url}`, this.getHttpOptions());
    }
    protected getHttpOptions(){
        const httpOptions = {
            headers: this.getHttpHeaders()
            //withCredentials: true //this is required so that Angular returns the Cookies received from the server. The server sends cookies in Set-Cookie header. Without this, Angular will ignore the Set-Cookie header
          };
        return httpOptions;
    }
    protected getHttpHeaders():HttpHeaders{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let token = this.cookiService.get('token');
        headers = headers.set("X-Auth-Token", token);
        console.log(`TOKEN SEND: ${token}`);
        return headers;
      }
}