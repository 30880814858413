import { Component, Input, OnInit } from '@angular/core';
import { EquipmentService } from '@dmonitor/services/equipment.service';
import { Equipment } from '@dmonitor/shared/dto';
import {FinisherData, FinisherSetting} from '@dmonitor/shared/response/sensor/finisher';

@Component({
  selector: 'finishergroupedsensor-status',
  templateUrl: 'status.component.pug',
  styleUrls: ['status.component.sass'],
})
export class FinisherStatusComponent implements OnInit {
  @Input() State: number;
  @Input() GroupId: string;
  @Input() Privilage: string;
  public Height:string;
  constructor(private equipmentService: EquipmentService){

  }
  public ngOnInit(): void {

  }
  public changeState(){
    this.equipmentService.changeFinisherGroupedSensorCollectionState(this.GroupId).subscribe();
  }
}
