import { Component, Input, OnInit } from '@angular/core';
import { Equipment } from '@dmonitor/shared/dto';
import {ThermometerData, ThermometerParameters} from '@dmonitor/shared/response/sensor/thermometer';

@Component({
  selector: 'thermometer-parameters',
  templateUrl: 'parameters.component.pug',
  styleUrls: ['parameters.component.sass'],
})
export class ParametersComponent implements OnInit {
  @Input() Parameters: ThermometerParameters;
  @Input() Type: number;
  @Input() Data: ThermometerData;
  @Input() Equipment: Equipment;
  @Input() StartExpanded: boolean = false;
  public expanded = false;

  public ngOnInit(): void {
    console.log(this.Parameters);
    console.log(`EXPAND ${this.StartExpanded}`);
    this.expanded = this.StartExpanded;
  }
  public expandItem(item): void {
    if (this.expanded) {
      this.expanded = false;
    } else {
      this.expanded = true;
    }
  }

  public parseFloat(num: string) {
    return parseFloat(num).toPrecision(6);
  }

  public divisionOp(num, devider, precision = 3) {
    if (num == null) {
      return 0;
    }
    if (num == 0) return 0;
    return (num / devider).toFixed(precision);
  }

  private getDeviceType(type: number): string {
    if (type == 0) {
      return "0...20 mA";
    }
    if (type == 1) {
      return "4...20 mA";
    }
    if (type == 2) {
      return "Frekvencia";
    }

    return "-";
  }

  private getComPeriod(type:number):string{
    if (type == 0) {
      return "2 min";
    }
    if (type == 1) {
      return "5 min";
    }
    if (type == 2) {
      return "10 min";
    }
    if (type == 3) {
      return "30 min";
    }
    if (type == 4) {
      return "60 min";
    }
    if (type == 5) {
      return "3 h";
    }
    if (type == 6) {
      return "6 h";
    }
    if (type == 7) {
      return "12 h";
    }
    return "-"





  }

}
