import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { SitesPage } from './sites.page';
import { MoreButtonComponent } from './component/more-button/more-button.component';
import { CompanyDialogModule } from '@dmonitor/dialogs/companydialog/company-dialog.module';
import { CountyDialogModule } from '@dmonitor/dialogs/countydialog/county-dialog.module';
import { SiteDialogModule } from '@dmonitor/dialogs/sitedialog/site-dialog.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: SitesPage
      }
    ]),
    TranslateModule.forChild(),
    CountyDialogModule,
    SiteDialogModule,
    CompanyDialogModule
  ],
  declarations: [SitesPage, MoreButtonComponent],
  entryComponents: [MoreButtonComponent]
})
export class SitesPageModule {}
