import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LogsDialogComponent } from './logs-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { IntegratorModule } from '@dmonitor/sensors/integrator/integrator.module';
import {ThermometerModule} from '@dmonitor/sensors/thermometer';
import {HygrometerModule} from '@dmonitor/sensors/hygrometer';
import {StatusindicatorModule} from '@dmonitor/sensors/statusindicator/statusindicator.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2SearchPipeModule,
    IntegratorModule,
    ThermometerModule,
    HygrometerModule,
    TranslateModule.forChild(),
    IonicModule,
    StatusindicatorModule,
  ],
  declarations: [LogsDialogComponent],
  entryComponents: [LogsDialogComponent]
})
export class LogsDialogModule {}
