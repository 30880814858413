import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@dmonitor/shared/index';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentService } from '@dmonitor/services/index';
import {TankDailyData, TankSetting} from '@dmonitor/shared/response/sensor/tank';

@Component({
  selector: 'tank-hourlydata-dialog',
  templateUrl: 'hourlydata-dialog.component.pug',
  styleUrls: ['hourlydata-dialog.component.sass'],
})
export class HourlyDataDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = "page.equipment.tank.hourlydata";
  @Input() Settings: TankSetting;
  @Input() Day: string;
  @Input() EquipmentId: string;
  public MaxIndex = [];
  public MinIndex = [];
  public HourlyData: TankDailyData[] = [];
  constructor(
    public modalController: ModalController,
    toastController: ToastController,
    loadingController: LoadingController,
    private equipmentService: EquipmentService,
    translateService: TranslateService) {
    super(toastController, loadingController, translateService);
  }
  public ngOnInit(): void {
    this.presentLoading();
    console.log(`EquipmentId: ${this.EquipmentId} day: ${this.Day}`);
    this.equipmentService.getTankDailyStatisticData(this.EquipmentId, this.Day).subscribe(
      (res) => {
        this.HourlyData = res.result;
        if (this.HourlyData.length == 0) {
          this.dismissLoading();
          return;
        }
        let min = 999999999;
        let max = 0;
        for (let i = 0; i < 24; i++) {
          if (this.HourlyData[i] == null) {
            continue;
          }
          if (this.HourlyData[i].l == null) {
            continue;
          }
          if (this.HourlyData[i].l == '-') {
            continue;
          }
          const value = Number(this.HourlyData[i].l);
          
          if (value < min) {
            min = value;
          }
          if (value > max) {
            max = value;
          }
        }

        for (let i = 0; i < 24; i++) {
          if (this.HourlyData[i] == null) {
            continue;
          }
          if (this.HourlyData[i].l == null) {
            continue;
          }
          if (this.HourlyData[i].l == '-') {
            continue;
          }
          const value = Number(this.HourlyData[i].l);
          if (value == min) {
            this.MinIndex.push(i);
          }
          if (value == max) {
            this.MaxIndex.push(i);
          }
        }
        this.dismissLoading();
      },
      (err) => {
        this.dismissLoading();
        this.showErrorToast('', err);
      });
  }

  public dismiss() {
    this.modalController.dismiss();
  }

  public IsMinIndex(idx: number): boolean {
    for (let i = 0; i < this.MinIndex.length; i++) {
      if (this.MinIndex[i] == idx) {
        return true;
      }
    }
    return false;
  }

  public IsMaxIndex(idx: number): boolean {
    for (let i = 0; i < this.MaxIndex.length; i++) {
      if (this.MaxIndex[i] == idx) {
        return true;
      }
    }
    return false;
  }

}
