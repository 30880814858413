import { Component, Input, OnInit } from '@angular/core';
import { Equipment } from '@dmonitor/shared/dto';
import {StatusindicatorData, StatusindicatorSetting} from '@dmonitor/shared/response/sensor/statusindicator';

@Component({
  selector: 'statusindicator-status',
  templateUrl: 'status.component.pug',
  styleUrls: ['status.component.sass'],
})
export class StatusComponent implements OnInit {
  @Input() Data: StatusindicatorData;
  @Input() Settings: StatusindicatorSetting;
  @Input() Equipment: Equipment;
  @Input() StartExpanded: boolean = false;
  public Height:string;
  public expanded = false;

  public ngOnInit(): void {

  }
  
  public expandItem(item): void {
    if (this.expanded) {
      this.expanded = false;
    } else {
      this.expanded = true;
    }
  }

  public parseFloat(num: string) {
    return parseFloat(num).toPrecision(6);
  }

  public divisionOp(num, devider, precision = 3) {
    if (num == null) {
      return 0;
    }
    if (num == 0) return 0;
    return (num / devider).toFixed(precision);
  }
}
