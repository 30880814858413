import { SensorPowerSupply } from '@dmonitor/sensors/index';
import { Parameter } from './parameter';

export interface FinisherResponse {
    Parameters: FinisherParameters,
    Data: FinisherData,
    Settings: FinisherSetting,
    Metadata:MetadataBlock,
    Active: number,
    Name: string
}
export interface MetadataBlock{
    id:string,
    name:string,
    data:Metadata[]
}
export interface Metadata{
    id:string,
    type:string,
    name:string,
    block_value:string,
    value:string,
    block_id:string,
    equipment_id:string
}

export interface FinisherSetting {
    equipment_id:number,
    power_supply:SensorPowerSupply,
    last_update: string,
    device_type:FinisherDeviceType,
    is_pic:number,
    is_temp:number,
    is_data_collection:number,
    sync_interval:number,
    check_date:string,
    min_temp_tick:number,
    max_temp_tick: number,
    gauge_ticks: number[],
    gauge_warn_min: number,
    gauge_warn_max: number,
    temp_correction: number
}

export interface FinisherData {
    id: string,
    equipment_id: string,
    pic_date: string,
    latitude: string,
    longitude: string,
    gps_date: string,
    t: string,
    t_date: string,
    package_id: string,
    hash:string,
    battery:number,
    is_charged:boolean,
    last_update: string,
    pic:string | null,
    location_accuracy: number,
    description:string
}

export interface FinisherParameters {
    Correction: Parameter,
    Unit: Parameter,
    Type: Parameter,
    Period: Parameter,
    EquipmentType:Parameter
}

export enum FinisherDeviceType{
    LEFT_DEVICE,
    MIDDLE_DEVICE,
    RIGHT_DEVICE
}
