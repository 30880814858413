import { CompanyService } from './company.service';
import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { Company, County, Site, Equipment } from '@dmonitor/shared/dto/index';
import { CountyService } from './county.service';
import { AuthService } from './auth.service';
import { SiteService } from './site.service';
import { AlertService } from './alert.service';
import { EquipmentService } from './equipment.service';
import { CompaniesResponse, SitesResponse, EquipmentsResponse, WhoamiResponse } from '@dmonitor/shared/response';
import { CountiesResponse } from '@dmonitor/shared/response/counties.response';
import { UserData } from '@dmonitor/shared/dto/userdata.dto';
import { LogService } from './log.service';
import { Alert } from '@dmonitor/shared/dto/alert.dto';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  
  public $IsSuperadminMode= new BehaviorSubject(false);
  private $initialized = new BehaviorSubject(false);
  private $companies = new BehaviorSubject<Company[]>(null);
  public Companies: Array<Company> = [];
  public LastListOfCompanies: Company[] = [];
  public Alerts: Alert[] = [];
  public $alerts = new BehaviorSubject<Alert[]>(null);
  public get AlertsForUser(): Observable<Alert[]> {
    return this.$alerts.asObservable();
  }
  public get IsCompaniesEmpty(): boolean {
    return (this.Companies == null || this.Companies.length == 0);
  }
  public get CompaniesForUser(): Observable<Company[]> {
    return this.$companies.asObservable();
  }


  public Counties: Array<County> = [];
  public LastListOfCounties: County[] = [];
  private $countiesForCompany = new BehaviorSubject<County[]>(null);
  public get IsCountiesEmpty(): boolean {
    return (this.Counties == null || this.Counties.length == 0);
  }
  public get CountiesForCompany(): Observable<County[]> {
    return this.$countiesForCompany.asObservable();
  }


  private $errorSubject = new BehaviorSubject<HttpErrorResponse>(null);



  public Sites: Array<Site> = [];
  public LastListOfSites: Site[] = [];
  private $sitesForCounty = new BehaviorSubject<Site[]>(null);
  public get SitesForCounty(): Observable<Site[]> {
    return this.$sitesForCounty.asObservable();
  }

  public Equipments: Array<Equipment> = [];
  //public LastListOfEquipments: Equipment[] = [];
  private $equipments = new BehaviorSubject<Equipment[]>(null);
  public get EquipmentsForSite(): Observable<Equipment[]> {
    return this.$equipments.asObservable();
  }
  public get Error(): Observable<HttpErrorResponse> {
    return this.$errorSubject.pipe(filter(e => e != null));
  }
  public GetEquipment(id: string): Equipment {
    const e = this.Equipments.filter(e => e.id == id);
    return e[0];
  }

  public get IsInited(): Observable<boolean> {
    return this.$initialized.pipe(filter(e => e == true));;

  }
  constructor(
    public AuthService: AuthService,
    public CompanyService: CompanyService,
    public CountyService: CountyService,
    public SiteService: SiteService,
    public AlertService: AlertService,
    public EquipmentService: EquipmentService,
    public LogService: LogService,
    public UserService: UserService
  ) {
    this.CompanyService.SelectionChanged().subscribe(() => {

    });
  }
  public InitializeFromData(data:WhoamiResponse): Observable<boolean>{
    this.resetSelected();
    this.Alerts = data.alerts;
    this.$alerts.next(this.Alerts);
    this.Companies = data.companies;
    this.$companies.next(this.Companies);
    this.Counties = data.counties;
    this.Sites = data.sites;
    this.Equipments = data.equipments;
    this.$initialized.next(true);
    return this.IsInited;
  }
  public Initialize(): Observable<boolean> {
    this.UserService.getUserData(this.UserService.getActualUserData().id).subscribe(res => {
      this.UserService.setUserData(res.result.userData);
      this.LoadCompanies().subscribe(() => {
        this.$initialized.next(true);
      });
      this.resetSelected();
    }, err => {
      this.$errorSubject.next(err);
    });

    return this.IsInited;
  }

  public LoggedInUser:UserData = null;

  public ReloadUserRights(): Observable<boolean> {
    const loading = new BehaviorSubject(null);
    this.UserService.whoami().subscribe(res => {
      debugger;
      this.Companies = res.result.companies;
      this.Counties = res.result.counties;
      this.Equipments = res.result.equipments;
      this.Sites = res.result.sites;
      this.UserService.setUserData(res.result.userData);
      this.$IsSuperadminMode.next(res.result.userData.superadmin == "1");

     // this.$companies.next(this.Companies);      
      loading.next(true);
    }, () => {
      debugger;
      
      loading.next(false);
    });
    return loading.pipe(filter(v => v != null));
  }


  public Reload(): void {
    this.UserService.whoami().subscribe(res => {
      debugger;
      this.Companies = res.result.companies;
      this.Counties = res.result.counties;
      this.Equipments = res.result.equipments;
      this.Sites = res.result.sites;
      this.UserService.setUserData(res.result.userData);
      this.$companies.next(this.Companies);      
    }, () => {
      debugger;
   
    });

  }

  private resetSelected(): void {
    this.CompanyService.SetSelected(null);
    this.CountyService.SetSelected(null);
    this.Counties = null;
    this.SiteService.SetSelected(null);
    this.Counties = null;
  }
  
  public SetSelectedCompany(companyId: string): void {
    this.CompanyService.SetSelected(companyId);
    const counties = this.Counties.filter(c => c.company_id == companyId);
    this.$countiesForCompany.next(counties);
    //this.LoadCounties();
  }


  public LoadCompanies(): Observable<boolean> {
    const loading = new BehaviorSubject(null);
    this.CompanyService.getCompaniesByCompanyArray(this.getUserCompanyIds()).subscribe(
      (res: CompaniesResponse) => {
        this.Companies = res.result;
        this.$companies.next(this.Companies);
        loading.next(true);
      },
      (err) => {
        this.$errorSubject.next(err);
        loading.next(false);
      },
      () => {
      }
    );
    return loading.pipe(filter(v => v != null));
    //this.CompanyService.Initialize(this.userCompanyIds);

  }

  public getUserCompanyIds():number[]{
    const res:number[] = [];
    this.Companies.forEach(company => {
      res.push(Number(company.id));
    });
    return res;
  }

  public GetCompany(id: string): Company {
    let c: Company = null;
    this.LastListOfCompanies.forEach((company: Company) => {
      if (id == company.id) {
        c = company;
      }
    });
    return c;
  }
  public GetRawCompany(id: string): Company {
    let c: Company = null;
    this.Companies.forEach((company: Company) => {
      if (id == company.id) {
        c = company;
      }
    });
    return c;
  }

  public GetSelectedCompany(): Company {
    return this.GetCompany(this.CompanyService.GetSelected());
  }



  public LoadCounties(): Observable<boolean> {
    const loading = new BehaviorSubject(null);
    if (this.CompanyService.GetSelected() == null) {
      this.$countiesForCompany.next(null);
      setTimeout(() => {
        loading.next(true);
      });
      return loading;
    }
    
    this.CountyService.getCountiesByCompanyArray(this.getUserCompanyIds()).subscribe(
      (res: CountiesResponse) => {
        const companyId = this.CompanyService.GetSelected();
        if (companyId == null) {

          this.$countiesForCompany.next(null);
          loading.next(true);
          return;
        }
        debugger;
        this.Counties = res.result;
        const counties = res.result.filter(c => c.company_id == companyId);
        this.$countiesForCompany.next(counties);
        loading.next(true);

      },
      (err) => {
        this.$errorSubject.next(err);
        loading.next(false);
      },
      () => {
      }

    );
    
    return loading;
  }


  public SetSelectedCounty(countyId: string): void {
    debugger;
    this.CountyService.SetSelected(countyId);
    const company = this.CompanyService.GetSelected();
    const sites = this.Sites.filter(c => c.company_id == company && c.county_id == countyId);
    this.$sitesForCounty.next(sites);
  }

  public GetSelectedCounty(): County {
    return this.GetCounty(this.CountyService.GetSelected());
  }

  public GetCounty(id: string): County {
    let c: County = null;
    this.LastListOfCounties .forEach((county: County) => {
      if (id == county.id) {
        c = county;
      }
    });
    return c;
  }

  public SetSelectedSite(siteId: string): void {
    this.SiteService.SetSelected(siteId);
    this.LoadEquipments();
  }

  public GetSite(id: string): Site {
    let c: Site = null;
    this.LastListOfSites.forEach((site: Site) => {
      if (id == site.id) {
        c = site;
      }
    });
    return c;
  }
  public GetSiteFromRaw(id: string): Site {
    let c: Site = null;
    this.Sites.forEach((site: Site) => {
      if (id == site.id) {
        c = site;
      }
    });
    return c;
  }

  public GetSelectedSite(): Site {
    return this.GetSite(this.SiteService.GetSelected());
  }


  public LoadSites(): Observable<boolean> {
    const loading = new BehaviorSubject(null);
    if (this.CompanyService.GetSelected() == null) {
      setTimeout(() => {
        loading.next(true);
      });
      return loading;
    }
    
    this.SiteService.getAllSitesForCompanies(this.getUserCompanyIds()).subscribe(
      (res: SitesResponse) => {
        const companyId = this.CompanyService.GetSelected();
        if (companyId == null) {
          this.$sitesForCounty.next(null);
          loading.next(true);
          return;
        }
        const countyId = this.CountyService.GetSelected();
        if (countyId == null) {
          this.$sitesForCounty.next(null);
          loading.next(true);
          return;
        }

        this.Sites = res.result.filter(c => c.company_id == companyId && c.county_id == countyId);
        //debugger;
        this.$sitesForCounty.next(this.Sites);
        loading.next(true);

      },
      (err) => {
        this.$errorSubject.next(err);
        loading.next(false);
      },
      () => {
      }

    );
    
    return loading;
  }

  public LoadEquipments(): void {

    if (this.SiteService.GetSelected() == null) {
      this.$equipments.next(null);
    }

    this.EquipmentService.getAllEquipments([this.SiteService.GetSelected()]).subscribe(
      (res: EquipmentsResponse) => {
        const companyId = this.CompanyService.GetSelected();
        if (companyId == null) {

          this.$equipments.next(null);
          return;
        }
        const countyId = this.CountyService.GetSelected();
        if (countyId == null) {
          this.$equipments.next(null);
          return;
        }
        const siteId = this.SiteService.GetSelected();
        if (siteId == null) {
          this.$equipments.next(null);
          return;
        }
        debugger
        this.Equipments = res.result.filter(c => c.site_id == siteId);
        this.$equipments.next(this.Equipments);

      },
      (err) => {
        this.$errorSubject.next(err);
      },
      () => {
      }

    );
  }
  public SetSelectedEquipment(equipmentId: string): void {
    this.EquipmentService.SetSelected(equipmentId);
  }


  public ReloadCompanies() {
    this.LoadCompanies();
  }
  public ReloadCounties() {
    this.LoadCounties();
  }
  public ReloadSites() {
    this.LoadSites();
  }
  public ReloadEquipments() {
    this.LoadEquipments();
  }

}