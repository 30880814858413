import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Equipment } from '@dmonitor/shared/dto';
import { TranslateService } from '@ngx-translate/core';
import { DataService, EquipmentService } from '@dmonitor/services/index';
import { BaseComponent } from '@dmonitor/shared/index';
import { SensorsType } from '@dmonitor/sensors/index';
import { FinisherSetting } from '@dmonitor/shared/response/sensor/finisher';

@Component({
  selector: 'finisher-edit-dialog',
  templateUrl: './finisher-edit-dialog.component.pug',
  styleUrls: ['./finisher-edit-dialog.component.sass'],
})
export class FinisherEditDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.finisher_edit_dialog';
  @Input() Settings: FinisherSetting | null = null;
  public SensorsTypes: typeof SensorsType = SensorsType;
  
  public title = '';
  

  public correction = new FormControl();
  
  

  public form = new FormGroup({
    correction: this.correction,

  });

  constructor(
    public modalController: ModalController,
    translateService: TranslateService,
    loadingController: LoadingController,
    private dataService: DataService,
    toastController: ToastController,
    private equipmentService: EquipmentService
  ) {
    super(toastController, loadingController, translateService);
  }


  public ngOnInit() {
    
    this.dismissLoading(1000);

    this.title = this.translate('temperature_correction');
    
    this.correction.setValue(this.Settings.temp_correction);
    
  }

  public dismiss(success: Boolean) {
    this.modalController.dismiss(success);
  }

  public onClickSubmit(data) {
    this.presentLoadingWith(1000, () => {
        this.edit();
    });
  }

  private edit(): void {
    this.Settings.temp_correction = this.correction.value;
    this.equipmentService.changeFinisherTempCorrection(this.Settings.equipment_id, this.correction.value).subscribe(
      (res: any) => {
        this.showSuccessToast(this.translate('msg_success_modify'));
        this.dismiss(true);
      },
      (error: any) => {
        this.dismissLoading();
        this.showErrorToast(this.translate('msg_save_error_modify'), error);
      }
    );
  }


}
