import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, forkJoin} from 'rxjs';
import { County } from '@dmonitor/shared/dto';
import { DefaultResponse } from '@dmonitor/shared/default-response';
import { CompanyService } from './company.service';
import { SelectableServiceBase } from './selectable.service.base';
import { UserService } from './user.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CountyService extends SelectableServiceBase<string>{

  private url = environment.apiUrl;
  private apiKey = ''; 
  private needsToReInitPageSubject = new BehaviorSubject(false);
  public ErrorSubject = new BehaviorSubject<HttpErrorResponse>(null);
  public counties : Array<County> = [];
  constructor(
    protected http: HttpClient,
    protected cookiService: CookieService,
    private companyService: CompanyService,
    private userService:UserService
  ) {
    super();
   }

  get NeedsToReInitPage(): BehaviorSubject<boolean>{
    return this.needsToReInitPageSubject;
  }

  /** Old one
   */
  public getCounties(companyId:number){
    return this.http.get<any>(`${environment.apiUrl}/county?cid=${companyId}&apikey=${environment.apiKey}`,this.getHttpOptions());
  }

  public getCountiesByCompanyArray(companyIds:Array<number>, adData:Array<number> = []){
    return this.http.get<any>(`${environment.apiUrl}/county?cid=${JSON.stringify(companyIds)}&ad=${JSON.stringify(adData)}&apikey=${environment.apiKey}`,this.getHttpOptions());
  }

  

  public add(countyName:string){
    
    const postData = JSON.stringify({
      name: countyName,
      company_id: this.companyService.GetSelected()
    });

    return this.http.post<object>(`${environment.apiUrl}/county/add?apikey=${environment.apiKey}`, postData, this.getHttpOptions());
  }

  public edit(countyId:string,countyName:string){
    

    const postData = JSON.stringify({
      id: countyId,
      name: countyName
    });

    return this.http.post<object>(`${environment.apiUrl}/county/edit?apikey=${environment.apiKey}`, postData, this.getHttpOptions());
  }

  public delete(countyId:string){
    

    const postData = JSON.stringify({
      id: countyId
    });

    return this.http.post<object>(`${environment.apiUrl}/county/delete?apikey=${environment.apiKey}`, postData, this.getHttpOptions());
  }

  public Initialize(){
      const companyIds:Array<number> = this.companyService.getCompanyIds();
      if(companyIds == null || companyIds.length == 0){
        console.error("CountyService initialization no comapnyIds");
        this.Inited().next(true);
        return;
      }
      /*
      this.getCountiesByCompanyArray(companyIds, []).subscribe(
        (res:DefaultResponse) => {
          let counties = res.result;
          let filteredCounties = [];
          counties.forEach((c: County) => {
            
            this.userService.getCountyPrivilege().forEach(
              (cp)=>{
                if(c.id == cp.county_id.toString() && cp.privilege_id.toString() != "0"){
                  filteredCounties.push(c);
                  return;
                }
              });
          });
          this.counties = filteredCounties;
          this.ErrorSubject.next(null);
          this.Inited().next(true);
        },
        (err) => {
          console.log(err);
          this.ErrorSubject.next(err);
        }
      );  
      */
  }

  public getSelectedCounty(){
    let c: County[];
    const selectedCountyId = this.GetSelected();
    c = this.counties.filter((couty)=>{
      return couty.id == selectedCountyId;
    })
    return c[0];
  }

  public setModified(county:County){
    this.counties.forEach((c:County) => {
      if(c.id == county.id){
        c.name = county.name;
      }
    });
  }

  public setNew(county:County){
    this.counties.push(county);
  }

  public getCountiesBySelectedCompanyId(){
    const selectedCompanyId = this.companyService.GetSelected();
    return this.counties.filter((county) => {
      return (county.company_id == selectedCompanyId);
    });
  }

  public setNeedsToReInitPage(reinit : boolean){
    this.needsToReInitPageSubject.next(reinit);
  }

}