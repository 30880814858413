import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { ModalController, ToastController, LoadingController, IonRefresher } from '@ionic/angular';
import { Equipment } from '@dmonitor/shared/dto';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@dmonitor/services/index';
import { BaseComponent } from '@dmonitor/shared/index';
import { SensorsType } from '@dmonitor/sensors/index';
import { OverlayEventDetail } from '@ionic/core';
import { LogDialogComponent } from '../logdialog/log-dialog.component';

@Component({
  selector: 'app-logs-dialog',
  templateUrl: './logs-dialog.component.pug',
  styleUrls: ['./logs-dialog.component.sass'],
})
export class LogsDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.logs_dialog';
  @Input() equipmentId: string;
  @Input() type: string;
  public SensorsTypes: typeof SensorsType = SensorsType;
  private equipment: Equipment;
  public LogNotFound:boolean = false;
  public title = '';
  public Items = []; 
  public filterTerm: string = '';
  @ViewChild(IonRefresher) public Refresher: IonRefresher;


  constructor(
    public modalController: ModalController,
    translateService: TranslateService,
    loadingController: LoadingController,
    private dataService: DataService,
    toastController: ToastController
  ) {
    super(toastController, loadingController, translateService);
  }


  public ngOnInit() {
    this.dismissLoading(1000);
    if(this.type == "2"){
      this.equipment = this.dataService.GetEquipment(this.equipmentId);
      this.title = this.translate(`${this.type}_title`) + this.equipment.name;  
    }else{
      this.title = this.translate(`${this.type}_title`);  
    }
    this.LoadList();
    //debugger;
  }
  protected LoadList(): void {
    this.dataService.LogService.GetLogFiles(this.type,this.equipment?.id).subscribe(res => {
      this.Items = [];
      res.result.forEach(element => {
        element.FileSize = parseFloat(""+element.FileSize/1024).toFixed(2)+" Kb";
        this.Items.push(element);
      });
      this.dismissLoading(500);
      this.$initialized.next(true);
      this.refreshComplete();
    }, err => {
      this.$initialized.next(true);
      if(err.status == 404){
        this.LogNotFound = true;
        return;
      }
      this.showErrorToast("Error:", err);
      this.dismissLoading(500);
    });

  }
  protected onRefresh(): void {
    this.LoadList();
  }

  protected refreshComplete(): void {
    if (this.Refresher == null) {
      return;
    }
    this.Refresher.complete();

  }
  protected doRefresh(timeout = 5000): void {
    this.onRefresh();
    setTimeout(() => {
      this.refreshComplete();
    }, 5000);
  }
  public dismiss(success: Boolean) {
    this.modalController.dismiss(success);
  }

  public onItemSelect(item:any):void{
    this.openLogModal(item.FileName);
  }
  public async openLogModal(fileName:string) {
    this.presentLoading();

    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: LogDialogComponent,
        componentProps: {
          type:this.type,
          fileName: fileName
        }
      });
    modal.onDidDismiss().then((detail: OverlayEventDetail) => {

      if (detail !== null && detail.data == true) {
        this.dataService.LoadSites();
      }
      this.dismissLoading();
    });

    await modal.present();

  }  
}
