import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FinisherGroupedSensorComponent } from './finishergroupedsensor.component';
import { SensorErrorModule } from '@dmonitor/sensors/sensorerror/sensorerror.module';
import { SensorLoadingModule } from '@dmonitor/sensors/sensorloading/sensorloading.module';
import { ExpandableModule } from '../expandable/expandable.module';
import { TranslateModule } from '@ngx-translate/core';
import { ResponsiveModule } from 'ngx-responsive';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BatteryModule } from '../battery/battery.module';
import { GsmSignalIndicatorModule } from '../gsmsignalindicator/gsmsignalindicator.module';
import { NgxLiquidGaugeModule } from 'ngx-liquid-gauge';
import { FinisherStatusComponent } from './status/status.component';
import { FinisherComponent } from './finisher/finisher.component';
import { FinisherStoredDataComponent } from './finisherstoreddata/finisherstoreddata.component';
import { GoogleGpsPipe } from './googlegps.pipe';
import { LineargaugeComponent } from './lineargauge/lineargauge.component';
import { FinisherEditDialogModule } from '@dmonitor/dialogs/finishereditdialog/finisher-edit-dialog.module';


@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      SensorErrorModule,
      BatteryModule,
      GsmSignalIndicatorModule,
      SensorLoadingModule,
      NgxLiquidGaugeModule,
      TranslateModule.forChild(),
      ResponsiveModule.forRoot(),
      NgxChartsModule,
      ExpandableModule,
      FinisherEditDialogModule,
      ReactiveFormsModule,
    ],
    exports: [FinisherGroupedSensorComponent],
    declarations: [
      FinisherGroupedSensorComponent,
      FinisherStatusComponent,
      FinisherComponent,
      FinisherStoredDataComponent,
      GoogleGpsPipe,
      LineargaugeComponent
    ]
  })
  export class FinisherGroupedSensorModule {}
