import { Component, OnInit } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { UserService, HomeService, DataService } from '@dmonitor/services/index';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../shared';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.page.pug',
  styleUrls: ['./changepassword.page.sass'],
})
export class ChangepasswordPage extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.change_password';
  public backLink:string;
  public passwordChangeForm: FormGroup ;
  constructor(
    toastController: ToastController,
    translateService: TranslateService,
    loadingController:LoadingController,
    private userService: UserService,
    private dataService: DataService,
    public formBuilder: FormBuilder,    
    private homeService: HomeService) {
      super(toastController,loadingController,translateService);
      this.passwordChangeForm = formBuilder.group({
        password: ['', Validators.required],
        passwordConfirm: ['', Validators.required]
      });
  }
  public ngOnInit() {
    this.backLink = "/"+this.homeService.getHome();
  }
  
  public onClickSubmit(){
    let passwordChangeData = this.passwordChangeForm.value;
    if((passwordChangeData.password != passwordChangeData.passwordConfirm) || passwordChangeData.password.length == 0 || passwordChangeData.passwordConfirm.length == 0){
      this.showFailedToast(this.translate("msg_validation_error"));
    }else{
      this.presentLoading();
      const user = this.dataService.LoggedInUser;
      this.userService.changePassword(Number(user.id), passwordChangeData.password).subscribe(
        (res:any) => {
          if(res.result.success == true){
            this.dismissLoading();
            this.showSuccessToast(this.translate("msg_success"));
          }else{
            this.showFailedToast(this.translate("msg_save_error"));
          }
        }
      );
    }
  }
}
