import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'gsmsignal',
    templateUrl: 'gsmsignalindicator.component.pug',
    styleUrls: [ 'gsmsignalindicator.component.sass'],
  })
  export class GsmSignalIndicatorComponent implements OnInit{
    @Input() public level:SignalStrength = null;
    SignalStrength = SignalStrength;
    public ngOnInit(): void {
     
    }
   
  }
  export enum SignalStrength{
      Marginal = 1,
      OK = 2,
      Good = 3,
      Excellent = 4,
      NoGsm = 5
  }
  