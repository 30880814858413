import { Component, Input, OnInit } from '@angular/core';
import { BatteryLevel } from '@dmonitor/sensors/battery/battery.component';
import { DeviceColors, DeviceType, SensorPowerSupply } from '@dmonitor/sensors/sensors.type';
import { IntegratorData, IntegratorSetting } from '@dmonitor/shared/response/sensor/integrator';

@Component({
  selector: 'integrator-simple-gauge',
  templateUrl: 'simple-gauge.component.pug',
  styleUrls: ['simple-gauge.component.sass'],
})
export class SimpleGaugeComponent implements OnInit {
  @Input() Data: IntegratorData;
  @Input() Settings: IntegratorSetting;
  @Input() GsmSignalStregth:number;
  @Input() BatteryLevel = BatteryLevel.LNULL;
  public TextColor = DeviceColors.COLD_WATER_TEXT_COLOR;
  public WaveColor = DeviceColors.COLD_WATER_WAVE_COLOR;
  public CircleColor = DeviceColors.COLD_CIRCLE_COLOR;
  public SensorPowerSupply = SensorPowerSupply;
  public ngOnInit(): void {
    if(this.Settings.device_type == DeviceType.HOT_WATER){
      this.TextColor = DeviceColors.HOT_WATER_TEXT_COLOR;
      this.WaveColor = DeviceColors.HOT_WATER_WAVE_COLOR;
      this.CircleColor = DeviceColors.HOT_CIRCLE_COLOR;
    
    }
  }
  private divisionOp(num, devider, precision = 3) {
    if (num == null) {
      return 0;
    }
    if (num == 0) return 0;
    return (num / devider).toFixed(precision);
  }

}