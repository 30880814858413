import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Equipment } from '@dmonitor/shared/dto';
import { TranslateService } from '@ngx-translate/core';
import { DataService, EquipmentService } from '@dmonitor/services/index';
import { BaseComponent } from '@dmonitor/shared/index';
import { SensorsType } from '@dmonitor/sensors/index';
import { FinisherSetting } from '@dmonitor/shared/response/sensor/finisher';
import { FinisherGroupedSensorGetEquipmentDataResponse } from '@dmonitor/shared/response/sensor/finishergroupedsensor';

@Component({
  selector: 'finisher-data-dialog',
  templateUrl: './finisher-data-dialog.component.pug',
  styleUrls: ['./finisher-data-dialog.component.sass'],
})
export class FinisherDataDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.finisher_data_dialog';
  @Input() equipmentId: string;
  @Input() siteId: string;
  public SensorsTypes: typeof SensorsType = SensorsType;
  private equipment: Equipment;
  private data: FinisherGroupedSensorGetEquipmentDataResponse;
  public title = '';
  

  public description = new FormControl();
  public form = new FormGroup({
    description: this.description,
  });

  constructor(
    public modalController: ModalController,
    translateService: TranslateService,
    loadingController: LoadingController,
    private dataService: DataService,
    toastController: ToastController,
    private equipmentService: EquipmentService
  ) {
    super(toastController, loadingController, translateService);
  }


  public ngOnInit() {
    
    this.dismissLoading(1000);

    this.title = this.translate('description');
    this.description.setValue("");
    this.equipmentService.getFinisherEquipmentData(this.equipmentId).subscribe(res => {
      this.data = res.result;
      this.description.setValue(this.data.data.description);
    });
    
    
  }

  public dismiss(success: Boolean) {
    this.modalController.dismiss(success);
  }

  public onClickSubmit(data) {
    this.presentLoadingWith(1000, () => {
      this.edit();
    });
  }

  private edit(): void {
    this.data.data.description = this.description.value;debugger;
    this.equipmentService.changeFinisherDescription(this.equipmentId, this.description.value).subscribe(
      (res: any) => {
        this.showSuccessToast(this.translate('msg_success_modify'));
        this.dismiss(true);
      },
      (error: any) => {
        this.dismissLoading();
        this.showErrorToast(this.translate('msg_save_error_modify'), error);
      }
    );
  }


}
