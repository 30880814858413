import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@dmonitor/shared/index';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentService } from '@dmonitor/services/index';
import {HygrometerDailyData} from '@dmonitor/shared/response/sensor/hygrometer';

@Component({
  selector: 'hygrometer-hourlydata-dialog',
  templateUrl: 'hourlydata-dialog.component.pug',
  styleUrls: ['hourlydata-dialog.component.sass'],
})
export class HourlyDataDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = "page.equipment.hygrometer.hourlydata";
  @Input() Day: string;
  @Input() EquipmentId: string;
  public MaxTempIndex = [];
  public MinTempIndex = [];
  public MaxHumidityIndex = [];
  public MinHumidityIndex = [];
  public HourlyData: HygrometerDailyData[] = [];
  constructor(
    public modalController: ModalController,
    toastController: ToastController,
    loadingController: LoadingController,
    private equipmentService: EquipmentService,
    translateService: TranslateService) {
    super(toastController, loadingController, translateService);
  }
  public ngOnInit(): void {
    this.presentLoading();
    console.log(`EquipmentId: ${this.EquipmentId} day: ${this.Day}`);
    this.equipmentService.getHygrometerDailyStatisticData(this.EquipmentId, this.Day).subscribe(
      (res) => {
        this.HourlyData = res.result;
        if (this.HourlyData.length == 0) {
          this.dismissLoading();
          return;
        }
        let minHumidity = 999;
        let maxHumidity = 0;
        let minTemp = 999999999;
        let maxTemp = -1000000;
        for (let i = 0; i < 24; i++) {
          if (this.HourlyData[i] == null) {
            continue;
          }
          if (this.HourlyData[i].t == null) {
            continue;
          }
          if (this.HourlyData[i].t == '-') {
            continue;
          }
          const valueTemp = Number(this.HourlyData[i].t);
          if (valueTemp < minTemp) {
            minTemp = valueTemp;
          }
          if (valueTemp > maxTemp) {
            maxTemp = valueTemp;
          }
          const valueHumidity = Number(this.HourlyData[i].h);
          if (valueHumidity < minHumidity) {
            minHumidity = valueHumidity;
          }
          if (valueHumidity > maxHumidity) {
            maxHumidity = valueHumidity;
          }
        }

        for (let i = 0; i < 24; i++) {
          console.log(this.HourlyData[i]);
          if (this.HourlyData[i] != null && this.HourlyData[i].t != null && this.HourlyData[i].t != '-') {
            const valueTemp = parseInt(this.HourlyData[i].t);
            if (valueTemp == minTemp) {
              this.MinTempIndex.push(i);
            }
            if (valueTemp == maxTemp) {
              this.MaxTempIndex.push(i);
            }
          }
          if (this.HourlyData[i] != null && this.HourlyData[i].h != null && this.HourlyData[i].h != '-') {
            const valueHumidity = parseInt(this.HourlyData[i].h);
            if (valueHumidity == minHumidity) {
              this.MinHumidityIndex.push(i);
            }
            if (valueHumidity == maxHumidity) {
              this.MaxHumidityIndex.push(i);
            }
          }
        }
        this.dismissLoading();
      },
      (err) => {
        this.dismissLoading();
        this.showErrorToast('', err);
      });
  }

  public dismiss() {
    this.modalController.dismiss();
  }

  public IsMinTempIndex(idx: number): boolean {
    for (let i = 0; i < this.MaxTempIndex.length; i++) {
      if (this.MaxTempIndex[i] == idx) {
        return true;
      }
    }
    return false;
  }

  public IsMaxTempIndex(idx: number): boolean {
    for (let i = 0; i < this.MaxTempIndex.length; i++) {
      if (this.MaxTempIndex[i] == idx) {
        return true;
      }
    }
    return false;
  }
  public IsMinHumidityIndex(idx: number): boolean {
    for (let i = 0; i < this.MinHumidityIndex.length; i++) {
      if (this.MinHumidityIndex[i] == idx) {
        return true;
      }
    }
    return false;
  }

  public IsMaxHumidityIndex(idx: number): boolean {
    for (let i = 0; i < this.MaxHumidityIndex.length; i++) {
      if (this.MaxHumidityIndex[i] == idx) {
        return true;
      }
    }
    return false;
  }

}
