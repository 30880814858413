import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RighteditDialogComponent } from './rightedit-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    IonicModule
  ],
  declarations: [RighteditDialogComponent],
  entryComponents: [RighteditDialogComponent]
})
export class RighteditDialogModule {}
