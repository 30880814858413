import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Equipment } from '@dmonitor/shared/dto';

import{ GroupedSensorResponse } from '@dmonitor/shared/response/sensor/groupedsensor';
import { DataService } from '@dmonitor/services/index';
import { UrlHelper } from '@dmonitor/shared/index';
@Component({
  selector: 'groupedsensor-sensorlist',
  templateUrl: 'sensorlist.component.pug',
  styleUrls: ['sensorlist.component.sass'],
})
export class SensorListComponent implements OnInit {
  
  @Input() Equipments: GroupedSensorResponse;
  @Input() StartExpanded: boolean = false;
  public expanded = false;
  constructor(
    private dataService: DataService,
    private navigateCtr: NavController,
  ) {}
  public ngOnInit(): void {
    
    console.log(`EXPAND ${this.StartExpanded}`);
    this.expanded = this.StartExpanded;
  }
  public expandItem(item): void {
    if (this.expanded) {
      this.expanded = false;
    } else {
      this.expanded = true;
    }
  }

  public onItemSelect(item: any) {
    this.dataService.SetSelectedEquipment(item.Id);
    this.navigateCtr.navigateForward(`/${UrlHelper.URL_EQUIPMENT}/0/0/0/${item.Id}`);
  }

  

  

}
